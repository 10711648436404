
import { mapGetters, mapActions } from 'vuex';
import SlideTransition from '~/components/transitions/SlideTransition';
import StelaLink from '~/components/common/StelaLink';
import defaultFooterData from '~/assets/data/footer-data';
import LOCALE from '~/assets/constants/LOCALE_DESTINATION_COUNTRY';

export default {
  name: 'StelaPageFooter',
  components: {
    SlideTransition,
    StelaLink,
  },
  data () {
    return {
      navMenuIsOpen: '',
    };
  },
  computed: {
    commonKey () {
      return 'footer-data';
    },
    footerData () {
      return this.allCommonData[this.commonKey] || {};
    },
    footerInfoContent () {
      return this.footerData?.footerInfoContent || defaultFooterData.footerInfoContent;
    },
    footerColumns () {
      return this.footerData?.footerColumns || defaultFooterData.footerColumns;
    },
    footerSocialLinks () {
      return this.footerData?.footerSocialLinks || defaultFooterData.footerSocialLinks;
    },
    footerCopyrightContent () {
      return this.footerData?.footerCopyrightContent || defaultFooterData.footerCopyrightContent;
    },
    countries () {
      return this.excludeFlaggedCountries(LOCALE.COUNTRIES, this.featureFlaggedDestinationCountries);
    },
    selectedCountry: {
      get () {
        return this.pageCountry;
      },
      async set (value) {
        this.setPageCountry(value);
        await window.scrollTo(0, 0);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
    },
    ...mapGetters({
      allCommonData: 'common/commonData',
    }),
  },
  methods: {
    toggleNavMenu (title) {
      if (this.navMenuIsOpen === title) {
        this.navMenuIsOpen = '';
        return;
      }
      this.navMenuIsOpen = title;
    },
    ...mapActions({
      setPageCountry: 'page/setPageCountry',
    }),
  },
};
