
import qs from 'qs';
import { mapGetters, mapActions } from 'vuex';
import OfflineNotice from '~/components/common/OfflineNotice';
import StelaPageHeader from '~/components/common/StelaPageHeader';
import StelaSideNavigations from '~/components/common/StelaSideNavigations';
import StelaPageFooter from '~/components/common/StelaPageFooter';

export default {
  components: {
    OfflineNotice,
    StelaPageHeader,
    StelaSideNavigations,
    StelaPageFooter,
  },
  props: {
    pageTitle: {
      type: String,
      default: null,
    },
    pageTitleUrl: {
      type: String,
      default: null,
    },
    isPrivatePage: {
      type: Boolean,
      default: false,
    },
    showMainMenu: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isLoggedIn () {
      return false;
    },
    ...mapGetters({
      showPageSidebar: 'page/showPageSidebar',
    }),
  },
  created () {
    if (this.isPrivatePage && !this.isLoggedIn) {
      // return to homepage.
      return this.$router.push('/');
    }
  },
  mounted () {
    this.maintainQueryParams();
  },
  beforeDestroy () {},
  methods: {
    filterQuery (mergedQuery) {
      const ALLOW_IF_QUERY_CONTAINS = ['utm'];
      const filteredQuery = {};
      Object.keys(mergedQuery).forEach((key) => {
        if (ALLOW_IF_QUERY_CONTAINS.some(k => key.includes(k))) {
          filteredQuery[key] = mergedQuery[key];
        }
      });
      return filteredQuery;
    },
    maintainQueryParams () {
      // maintain query & utm params when navigating to other auth pages
      const queryString = qs.stringify(this.$route.query);
      if (queryString === '') {
        return;
      }
      const anchorTags = document.getElementsByTagName('a');
      for (let i = 0; i < anchorTags.length; i++) {
        const anchorTag = anchorTags[i];
        const href = anchorTag.getAttribute('href');
        if (href && href.includes('heroshe.com') && href.startsWith('http')) {
          const url = new URL(href);
          const origin = url.origin;
          const path = url.pathname;
          // merge query params
          const query = qs.parse(url.search, { ignoreQueryPrefix: true });
          const mergedQuery = { ...query, ...this.$route.query };
          const mergedQueryString = qs.stringify(this.filterQuery(mergedQuery));
          if (mergedQueryString === '') {
            return;
          }
          const newHref = `${origin}${path}?${mergedQueryString}`;
          anchorTag.setAttribute('href', newHref);
        }
      }
    },
    ...mapActions({
      togglePageSidebar: 'page/togglePageSidebar',
    }),
  },
};
