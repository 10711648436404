export default {
  footerInfoContent: {
    title: 'Experience timely, affordable and hassle free shipping from the US',
    imageUrl: '/svg/logo/heroshe.svg',
  },
  footerColumns: [
    {
      title: 'Solutions',
      links: [
        {
          text: 'Personal',
          url: '/solutions/personal-shipping',
        },
        {
          text: 'Business',
          url: '/solutions/business',
        },
        {
          text: 'Drop Shipping',
          url: '/solutions/drop-shipping',
        },
        {
          text: 'Education',
          url: '/solutions/education',
        }
      ],
      id: '616d3f262d7cbd0013222050'
    },
    {
      title: 'Resources',
      links: [
        {
          text: 'Help Center',
          url: '/help-center',
        },
        {
          text: 'Blog',
          url: 'https://blog.heroshe.com',
        },
        {
          text: 'Pricing',
          url: '/pricing',
        }
      ],
    },
    {
      title: 'Company',
      links: [
        {
          text: 'About Us',
          url: '/about',
        },
        {
          text: 'Contact Us',
          url: '/contact',
        },
        {
          text: 'Press Kit',
          url: 'https://heroshe-public-assets.s3.eu-west-2.amazonaws.com/media-kit.zip',
        },
        {
          text: 'Legal',
          url: '/legal',
        }
      ],
    }
  ],
  footerCopyrightContent: {
    title: `© ${new Date().getFullYear()} Heroshe ‐ All rights reserved`,
    links: [
      {
        url: '/legal/terms',
        text: 'Terms of Service',
      },
      {
        url: '/legal/privacy-policy',
        text: 'Privacy Policy',
      }
    ],
  },
  footerSocialLinks: [
    {
      url: 'https://linkedin.com/company/heroshe',
      title: 'Heroshe LinkedIn',
      iconUrl: '/svg/socials/linkedin.svg',
    },
    {
      title: 'Heroshe Facebook',
      url: 'https://facebook.com/shopheroshe',
      iconUrl: '/svg/socials/facebook.svg',
    },
    {
      title: 'Heroshe Twitter',
      url: 'https://twitter.com/shipheroshe',
      iconUrl: '/svg/socials/twitter.svg',
    },
    {
      title: 'Heroshe Youtube Channel',
      url: 'https://youtube.com/user/HerosheTV',
      iconUrl: '/svg/socials/youtube.svg',
    },
    {
      title: 'Heroshe Instagram',
      url: 'https://instagram.com/shipheroshe',
      iconUrl: '/svg/socials/instagram.svg',
    }
  ],
};
